._2VCiWipAb1 {
  background: -webkit-linear-gradient(left, #dfdfdf, #dfdfdf 5px, #fff 5px);
  background: -moz-linear-gradient(left, #dfdfdf, #dfdfdf 5px, #fff 5px);
  background: -o-linear-gradient(left, #dfdfdf, #dfdfdf 5px, #fff 5px);
  background: -ms-linear-gradient(left, #dfdfdf, #dfdfdf 5px, #fff 5px);
  background: linear-gradient(to right, #dfdfdf, #dfdfdf 5px, #fff 5px);
  border-top: 1px solid #efefef;
  border-right: 1px solid #efefef;
  border-color: #f8f8f8;
  border-radius: 6px;
  margin-bottom: 1rem;
  -webkit-box-shadow: 0 1px 1px 0 #dfdfdf;
  box-shadow: 0 1px 1px 0 #dfdfdf;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 1px;
}
._2VCiWipAb1._1kvcse0CFT {
  border-left-width: 0.25rem;
}
._2VCiWipAb1._1kvcse0CFT ._3ttGSaIMFn {
  margin-right: 1.25rem;
  margin-left: 0;
}
._2VCiWipAb1._1kvcse0CFT .fGmM7Iv_Ul {
  margin: 0.75rem 0.75rem 0.75rem 1.5rem;
}
._2VCiWipAb1.mhEE2Aod7E .fGmM7Iv_Ul {
  margin-right: 8rem;
}
._2VCiWipAb1._2RNLW6txLa {
  background: -webkit-linear-gradient(left, #ff5c5c, #ff5c5c 5px, #fff 5px);
  background: -moz-linear-gradient(left, #ff5c5c, #ff5c5c 5px, #fff 5px);
  background: -o-linear-gradient(left, #ff5c5c, #ff5c5c 5px, #fff 5px);
  background: -ms-linear-gradient(left, #ff5c5c, #ff5c5c 5px, #fff 5px);
  background: linear-gradient(to right, #ff5c5c, #ff5c5c 5px, #fff 5px);
  color: #ff5c5c;
}
._2VCiWipAb1._1_CXqzMS-s {
  background: -webkit-linear-gradient(left, #ffc464, #ffc464 5px, #fff 5px);
  background: -moz-linear-gradient(left, #ffc464, #ffc464 5px, #fff 5px);
  background: -o-linear-gradient(left, #ffc464, #ffc464 5px, #fff 5px);
  background: -ms-linear-gradient(left, #ffc464, #ffc464 5px, #fff 5px);
  background: linear-gradient(to right, #ffc464, #ffc464 5px, #fff 5px);
  color: #d18100;
}
._2VCiWipAb1._3q1gUvscTS {
  background: -webkit-linear-gradient(left, #2380ff, #2380ff 5px, #fff 5px);
  background: -moz-linear-gradient(left, #2380ff, #2380ff 5px, #fff 5px);
  background: -o-linear-gradient(left, #2380ff, #2380ff 5px, #fff 5px);
  background: -ms-linear-gradient(left, #2380ff, #2380ff 5px, #fff 5px);
  background: linear-gradient(to right, #2380ff, #2380ff 5px, #fff 5px);
  color: #2380ff;
}
._2VCiWipAb1._-2xa8OT3th {
  background: -webkit-linear-gradient(left, #24c833, #24c833 5px, #fff 5px);
  background: -moz-linear-gradient(left, #24c833, #24c833 5px, #fff 5px);
  background: -o-linear-gradient(left, #24c833, #24c833 5px, #fff 5px);
  background: -ms-linear-gradient(left, #24c833, #24c833 5px, #fff 5px);
  background: linear-gradient(to right, #24c833, #24c833 5px, #fff 5px);
  color: #24c833;
}
._2VCiWipAb1 ._3jRY6wvQGR {
  line-height: 1;
  display: inline-block;
  white-space: nowrap;
  margin: 0 0 0.75rem;
  font-weight: 600;
}
._2VCiWipAb1 ._1fWl5KT6Vv {
  display: inline-block;
  color: #363636;
}
._2VCiWipAb1 .fGmM7Iv_Ul {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: 2rem;
}
._2VCiWipAb1 ._3ttGSaIMFn {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-right: 1.75rem;
  margin-left: 1rem;
}
